<template>
  <div class="privacy">
    <div id="steps-section">
      <div class="faq_section">
        <h1 class="privacy_heading">Allgemeine Geschäftsbedingungen</h1>

        <div class="content">
          Wilkommen bei Symp. Diese Allgemeinen Geschäftsbedingungen gelten
          zwischen Ihnen und uns: <br />
          Symp UG (haftungsbeschränkt) <br />
          Birkenstraße 14 <br />
          67067 Ludwigshafen, Deutschland
        </div>

        <div class="sub_subheading">Inhalt</div>
        <div class="content">
          <ul>
            <li>Annahme der AGB</li>
            <li>Nutzungsberechtigung</li>
            <li>Sicherheit</li>
            <li>Änderung unserer Dienste</li>
            <li>Konto und Kündigung</li>
            <li>Käufe</li>
            <li>Dienste Dritter</li>
            <li>Haftungsausschluss</li>
            <li>Haftungsbeschränkung</li>
          </ul>
        </div>
        <div class="sub_subheading">1. Annahme der AGB</div>
        <div class="content">
          Durch das Erstellen eines Kontos bei Symp, erklären Sie sich damit
          einverstanden, an diese AGB, unsere
          <router-link to="/privacy">Datenschutzerklärung</router-link> sowie an
          sämtliche Bedingungen, die von Ihnen offengelegt werden und denen Sie
          zugestimmt haben, wenn Sie Produkte oder Dienste erwerben, die wir im
          Rahmen unseres Dienstes anbieten, gebunden zu sein. Sollten Sie nicht
          alle Bedingungen dieses Vertrags annehmen und sich mit diesen nicht
          einverstanden erklären, bitten wir Sie, unseren Dienst nicht zu
          nutzen. Wir sind berechtigt, von Zeit zu Zeit Änderungen an diesem
          Vertrag und an unserem Dienst vorzunehmen. Die aktuelle Version ist
          immer die Version, die Gültigkeit hat. Wenn die Änderungen bedeutende
          Änderungen umfassen, die Ihre Rechte oder Pflichten betreffen, werden
          wir Sie spätestens 30 Tage im Voraus auf angemessene Weise über diese
          Änderungen informieren (es sei denn, wir sind dazu nach geltendem
          Recht nicht in der Lage), z. B. per Mitteilungen über den Dienst. Wenn
          Sie den Dienst weiterhin nutzen, nachdem die Änderungen wirksam
          werden, stimmen Sie dem überarbeiteten Vertrag zu.
        </div>
        <div class="sub_subheading">2. Nutzungsberechtigung</div>
        <div class="content">
          Sie müssen mindestens 18 Jahre alt sein, um den Dienst nutzen zu
          können, und es darf Ihnen nicht gesetzlich untersagt sein, den Dienst
          zu nutzen. Sie verpflichten sich, das Gesetz einzuhalten, und
          erklären, dass Sie kein Verbrechen begangen haben und kein
          Sexualstraftäter sind
        </div>
        <div class="sub_subheading">3. Sicherheit</div>
        <div class="content">
          Symp ist nicht für das Verhalten von Nutzern innerhalb oder außerhalb
          des Dienstes verantwortlich. Sie erklären sich damit einverstanden,
          bei allen Interaktionen mit anderen Nutzern vorsichtig vorzugehen,
          insbesondere, wenn Sie sich dazu entschließen, außerhalb des Dienstes
          zu kommunizieren oder die Nutzer persönlich zu treffen. Sie stimmen
          zu, keine finanzrelevanten Informationen (z. B. Ihre Kreditkarten-
          oder Bankkontodaten) gegenüber anderen Nutzern preiszugeben bzw. kein
          Geld durch Überweisung oder anderweitig an diese zu versenden. Sie
          sind allein für Ihre Interaktionen mit anderen Nutzern verantwortlich.
          Sie sind sich darüber bewusst, dass Symp keine Überprüfung der
          kriminellen Vergangenheit seiner Nutzer vornimmt oder sich anderweitig
          über die Vergangenheit und Hintergründe seiner Nutzer erkundigt. Symp
          gibt keine Zusicherungen oder Garantien hinsichtlich des Verhaltens
          oder der Vereinbarkeit der Nutzer.
        </div>
        <div class="sub_subheading">4. Änderung unserer Dienste</div>
        <div class="content">
          Wir können die Dienste jederzeit ändern oder sie ganz einstellen.
        </div>
        <div class="sub_subheading">5. Konto und Kündigung</div>
        <div class="content">
          Sie erklären sich damit einverstanden, Ihr Konto sicher und
          vertraulich zu halten. Sie können Ihr Konto unter „Einstellungen“
          kündigen, und wir behalten uns das Recht vor, dieses für Sie zu
          kündigen, wenn Sie gegen diesen Vertrag verstoßen, oder wenn wir Ihre
          Nutzung des Dienstes für unangemessen halten.
        </div>
        <div class="sub_subheading">6. Käufe</div>
        <div class="content">
          <div class="sub_sub_subheading">Allgemeines</div>
          Symp kann Produkte oder Dienste zum Kauf ("In-App-Käufe") über den App
          Store oder den Google Play Store anbieten. Wenn Sie sich für einen
          In-App-Kauf entscheiden, werden Sie aufgefordert, Ihren Kauf bei dem
          entsprechenden Zahlungsanbieter zu bestätigen, und Ihre
          Zahlungsmethode wird für den In-App-Kauf zu den Preisen angewandt, die
          für die von Ihnen ausgewählten Dienste angezeigt werden, sowie
          jegliche Mehrwertsteuer oder ähnliche Steuern, die möglicherweise auf
          Ihre Zahlungen erhoben werden, und Sie ermächtigen den Drittanbieter,
          falls zutreffend, die entsprechende Gebühr in Rechnung zu stellen.
          <div class="sub_sub_subheading">Automatische Erneuerung</div>
          Wenn Sie ein automatisch wiederkehrendes, regelmäßiges Abonnement über
          einen In-App-Kauf erwerben, wird Ihre Bezahlmethode fortlaufend, bis
          zu Ihrer Kündigung, für das Abonnement belastet. Nach Ihrer
          anfänglichen Abonnementbindungsdauer und erneut nach jeder folgenden
          Abonnementdauer läuft Ihr Abonnement automatisch für eine
          entsprechende Dauer weiter, und zwar zu dem Preis, dem Sie beim
          Abschluss zugestimmt haben. Einwände gegen eine bereits geleistete
          Zahlung müssen an das entsprechende Drittanbieter-Konto wie den App
          Store gerichtet werden. Sie können auch Einspruch erheben, indem Sie
          Ihren Bezahldienst kontaktieren, der Ihnen weitere Informationen über
          Ihre Rechte und über geltende Fristen zur Verfügung stellen kann. Sie
          können Ihre Einwilligung zu automatischen Kartenzahlungen jederzeit in
          dem entsprechenden Konto des Drittanbieters bedingungslos widerrufen.
          Wir weisen Sie jedoch darauf hin, dass Sie weiterhin zur Zahlung
          ausstehender Beträge verpflichtet sind.
          <div class="sub_sub_subheading">
            Wenn Sie Ihr Abonnement ändern oder beenden möchten, müssen Sie sich
            in Ihr Konto beim Drittanbieter einloggen und den Anweisungen zur
            Beendigung oder Kündigung Ihres Abonnements folgen, selbst wenn Sie
            Ihr Konto bei uns oder die Symp-App von Ihrem Gerät gelöscht haben.
          </div>
          Die Löschung Ihres Kontos bei Symp oder das Löschen der Symp App von
          Ihrem Gerät führt nicht zur Beendigung oder Kündigung Ihres
          Abonnements; Symp behält sämtliche Gelder, mit denen Ihre
          Bezahlmethode belastet wird, bis Sie Ihr Abonnement über Ihr
          Drittanbieter-Konto, soweit zutreffend, beenden oder kündigen. Wenn
          Sie Ihr Abonnement beenden oder kündigen, können Sie Ihr Abonnement
          bis zum Ende der Laufzeit des aktuellen Abonnementzeitraums nutzen und
          Ihr Abonnement wird sich nicht verlängern, wenn der Zeitraum abläuft.
          <div class="sub_sub_subheading">Virtuelle Gegenstände</div>
          Sie können eine begrenzte, persönliche, nicht übertragbare, nicht
          unterlizenzierbare, widerrufliche Lizenz zur Verwendung "virtueller
          Gegenstände" erwerben oder erhalten, die sogenannten „Symps“, welche
          dazu genutzt werden können andere Nutzer zu sympen. Jegliche in Ihrem
          Konto angezeigten Salden für virtuelle Gegenstände stellen keine
          echten Salden dar oder geben keinen gespeicherten Wert wieder, sondern
          erfassen den Umfang Ihrer Lizenz. Für die Nichtbenutzung von
          virtuellen Gegenständen fällt keine Gebühr an; die Ihnen für virtuelle
          Gegenstände gewährte Lizenz endet jedoch gemäß den Bestimmungen dieses
          Vertrags, wenn Symp die Bereitstellung des Dienstes einstellt oder Ihr
          Konto anderweitig geschlossen oder gekündigt wird. Symp behält sich
          nach alleinigem Ermessen das Recht vor, Gebühren für den Zugriff auf
          oder die Nutzung von virtuellen Gegenständen zu erheben, und kann
          virtuelle Gegenstände mit oder ohne Gebühr bereitstellen. Symp kann
          virtuelle Gegenstände jederzeit verwalten, regulieren, kontrollieren,
          verändern oder entfernen. Symp haftet weder Ihnen noch Dritten
          gegenüber, falls Symp jegliche dieser Rechte ausübt. Virtuelle
          Gegenstände können nur über den Dienst eingelöst werden. Jegliche
          Käufe und Einlösungen von virtuellen Gegenständen, die durch den
          Dienst getätigt werden, sind endgültig und nicht erstattungsfähig. Die
          Bereitstellung virtueller Gegenstände zur Nutzung im Rahmen des
          Dienstes ist eine Leistung, die unmittelbar mit der Annahme Ihres
          Kaufs solcher virtuellen Gegenstände beginnt. Sie erkennen an, dass
          Symp aus keinerlei Gründen zu einer Rückerstattung virtueller
          Gegenstände verpflichtet ist, und dass sie bei einer Kontoschließung
          kein Geld oder eine andere Vergütung für nicht verwendete virtuelle
          Gegenstände erhalten, egal ob die Schließung freiwillig oder
          unfreiwillig war.
          <div class="sub_sub_subheading">Rückerstattungen</div>
          Generell sind alle Gebühren für Käufe nicht erstattungsfähig und es
          gibt keine Rückerstattungen oder Gutschriften für teilweise
          verbrauchte Zeiträume. Wir können eine Ausnahme machen, wenn eine
          Rückerstattung für ein Abonnementangebot innerhalb von vierzehn Tagen
          nach dem Datum der Transaktion angefordert wird, oder wenn die in
          Ihrer Gerichtsbarkeit geltenden Gesetze Rückerstattungen vorsehen. Als
          Abonnenten mit Wohnsitz in der EU oder im Europäischen Wirtschaftsraum
          haben Sie entsprechend der lokalen Gesetze innerhalb von 14 Tagen nach
          Beginn des Abonnements Anspruch auf eine Rückerstattung in voller
          Höhe, ohne Gründe angeben zu müssen. Beachten Sie bitte, dass diese
          14-tägige Frist mit Beginn des Abonnements beginnt. Käufe von
          virtuellen Gegenständen sind rechtskräftig und nicht erstattungsfähig.
          Anforderung von Rückerstattungen: Wenn Sie einen Kauf unter Verwendung
          Ihrer Apple-ID abgeschlossen haben, werden Erstattungen über Apple
          abgewickelt, nicht über Symp. Um eine Rückerstattung anzufordern,
          gehen Sie zum App Store, klicken Sie auf Ihre Apple-ID, wählen Sie
          "Kaufhistorie" aus, suchen Sie die Transaktion und drücken Sie auf
          "Problem melden". Zudem können Sie auch ein Gesuch über
          https://getsupport.apple.com einreichen. Wenn Sie einen Kauf unter
          Verwendung Ihres Kontos im Google Play Store abgeschlossen haben:
          Kontaktieren Sie den Kundensupport unter Angabe Ihrer Bestellnummer
          für den Google Play Store (Sie finden die Bestellnummer in der
          Bestellbestätigungs-E-Mail, oder indem Sie sich bei Google Wallet
          anmelden). Wenn Sie von Ihrem Recht auf Stornierung Gebrauch machen
          (mit Ausnahme von Käufen, die Sie über Ihre Apple-ID getätigt haben
          und die von Apple reguliert werden), erstatten wir (oder fordern
          Google zur Erstattung auf) alle Zahlungen, die wir von Ihnen erhalten
          haben, unverzüglich und in jedem Fall innerhalb von 14 Tagen nach
          Empfang Ihrer Vertragskündigung. Die Rückerstattung erfolgt unter
          Verwendung der gleichen Zahlungsmittel, die Sie bei der ursprünglichen
          Transaktion verwendet haben. In keinem Fall werden Ihnen aufgrund der
          Rückerstattung Gebühren berechnet. Wenn Sie einen Kauf über eine
          Bezahlplattform abgeschlossen haben, die nicht oben aufgeführt ist,
          fordern Sie eine Erstattung bitte direkt beim Drittanbieter an, über
          den Sie den Kauf getätigt haben. Sie können eine Bestellung für die
          Bereitstellung von digitalen Inhalten, die nicht auf einem physischen
          Datenträger geliefert werden, nicht stornieren, wenn die
          Bestellabwicklung mit Ihrer ausdrücklichen vorherigen Zustimmung und
          der Bestätigung, dass Sie dadurch Ihr Widerrufsrecht verlieren,
          begonnen hat. Dies gilt z. B. für Käufe von virtuellen Gegenständen.
          Das bedeutet: Diese Käufe sind endgültig und nicht erstattungsfähig.
        </div>
        <div class="sub_subheading">7. Dienste Dritter</div>
        <div class="content">
          Der Dienst kann Werbung und Werbeaktionen, die von Dritten angeboten
          werden, sowie Links zu anderen Websites oder Internet-Ressourcen
          enthalten. Symp ist nicht verantwortlich für die Verfügbarkeit (oder
          mangelnde Verfügbarkeit) solcher externen Websites oder
          Internet-Ressourcen. Wenn Sie beschließen, mit Dritten über unseren
          Dienst zu interagieren, unterliegt deren Beziehung mit Ihnen den
          Bedingungen dieser Dritten. Symp ist nicht verantwortlich oder haftbar
          für die Bedingungen oder Maßnahmen dieser Dritten.
        </div>
        <div class="sub_subheading">8. Haftungsausschlüsse</div>
        <div class="content">
          Symp stellt den Dienst ohne Mängelgewähr und in der verfügbaren Form
          sowie in dem Umfang, der durch geltendes Recht zulässig ist, bereit
          und gewährt keine Garantien jedweder Art, ob ausdrücklich,
          stillschweigend, gesetzlich oder anderweitig im Hinblick auf den
          Dienst einschließlich, aber nicht beschränkt auf jedwede
          stillschweigende Garantie für zufriedenstellende Qualität,
          Marktgängigkeit, Eignung für einen bestimmten Zweck oder
          Nichtverletzung. Symp sichert nicht zu und garantiert nicht, dass
          unser Dienst ununterbrochen, sicher oder fehlerfrei sein wird, etwaige
          Mängel oder Fehler im Dienst behoben werden, oder dass Inhalte oder
          Informationen, die sie auf unserem Dienst oder durch diesen erhalten,
          korrekt sind. Symp übernimmt keine Verantwortung für Inhalte, die sie,
          andere Mitglieder oder dritte über unseren Dienst posten, versenden
          oder erhalten. Der Zugriff auf jegliches Material, das heruntergeladen
          oder durch die Nutzung unseres Dienstes anderweitig erhalten wird,
          erfolgt nach eigenem Ermessen und auf eigene Gefahr.
        </div>
        <div class="sub_subheading">9. Haftungsbeschränkung</div>
        <div class="content">
          Im größtmöglichen durch geltendes Recht zulässigen Umfang übernimmt
          Symp keine Haftung für indirekte, exemplarische, zufällige, spezielle
          oder erweiterte Schäden sowie für Folgeschäden oder Strafschäden,
          einschließlich, aber nicht beschränkt auf entgangene Gewinne, ob
          direkt oder indirekt verursacht, oder Datenverluste,
          Firmenwertverluste, entgangene Nutzung oder andere immaterielle
          Verluste, die sich aus Folgendem ergeben: (a) Ihrer Nutzung oder Ihrem
          Zugriff auf unsere Dienste bzw. Ihrer Unfähigkeit, unseren Dienst zu
          nutzen, (b) dem Verhalten oder den Inhalten anderer Nutzer oder
          Dritter in Verbindung mit der Nutzung unseres Dienstes; oder (c)
          unerlaubtem Zugriff, unerlaubter Nutzung oder unerlaubter Veränderung
          Ihrer Inhalte, selbst wenn Symp auf die Möglichkeit solcher Schäden
          hingewiesen wurde. Keinesfalls aber übersteigt die Gesamthaftung von
          Symp Ihnen gegenüber in Bezug auf alle Ansprüche im Zusammenhang mit
          dem Dienst den von Ihnen gegebenenfalls an Symp für unseren Dienst
          gezahlten Betrag und 100 Euro, während Sie über ein Konto verfügen.
        </div>
      </div>
    </div>
    <div class="footer_links">
      <router-link to="/terms"> AGB's </router-link>
      <router-link to="/privacy"> Datenschutz </router-link>
      <router-link to="/imprint"> Impressum </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Terms",

  methods: {
    handleSignupClick() {
      this.$emit("event", "get_started_click");
    },
  },
};
</script>

<style scoped>
.privacy {
  max-width: 1332px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  margin-top: 32px;
  padding: 32px 45px;
  box-sizing: border-box;
}

.footer_links {
  margin-top: 64px;
}

.footer_links a {
  text-decoration: none;
  margin: 0 16px;
  color: #fff;
}

.privacy_heading {
  font-size: 48px;
  margin: 32px 0 0 0;
  word-break: break-all;
  word-wrap: break-word;
  color: #fff;
  font-weight: 700;
  text-align: left;
}

.subheading {
  margin: 63px 0;
  font-size: 24px;
  font-weight: 600;
}

.sub_subheading {
  font-size: 18px;
  margin: 64px 0 0 0;
  font-weight: 500;
}

.sub_subheading_1 {
  font-size: 18px;
  margin: 0 0 0 0;
  font-weight: 500;
}

.hint {
  color: #797676;
  font-size: 14px;
  margin-top: 16px;
}

.sub_sub_subheading {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 600;
}

.content {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 32px;
  padding: 32px;
  border-radius: 16px;
  color: #d6bcff;
  box-sizing: border-box;
  background-color: #221cc0;
}

.content ul {
  box-sizing: border-box;
  padding: 0 24px;
  list-style-type: decimal;
}

.content .normal_list {
  list-style-type: circle;
}

.content li {
  color: #d6bcff;
  margin-bottom: 16px;
}

.content li:last-child {
  margin: 0;
}

.content li::marker {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  text-align: left;
}

.content li ul {
  list-style-type: circle;
}
</style>
