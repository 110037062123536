<template>
  <div class="imprint">
    <div id="steps-section">
      <div class="legal-heading">
        <h1 class="section-heading">Impressum</h1>
      </div>
      <div class="legal-content">
        Symp UG (haftungsbeschränkt)
        <br />
        Birkenstraße 14, 67067 Ludwigshafen
        <br />
        Deutschland
        <br />
        E-Mail: contact@symp.me
        <br />
        Web: www.symp.me
      </div>
    </div>
    <div class="footer_links">
      <router-link to="/terms"> AGB's </router-link>
      <router-link to="/privacy"> Datenschutz </router-link>
      <router-link to="/imprint"> Impressum </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Imprint",

  methods: {
    handleSignupClick() {
      this.$emit("event", "get_started_click");
    },
  },
};
</script>

<style scoped>
.imprint {
  width: 100%;
  height: 100%;
}

#steps-section {
  padding: 100px 45px;
  position: relative;
  max-width: 1326px;
  margin: 0 auto 144px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
}

.legal-content {
  margin-top: 24px;
  font-size: 16px;
  color: #fff;
  font-weight: 300;
  line-height: 1.5;
}

#separator {
  height: 45px;
}

.footer_links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 64px 0 32px 0;
}

.footer_links a {
  text-decoration: none;
  margin: 0 16px;
  color: #fff;
}

h3 {
  font-size: 16px;
  color: #fff;
  line-height: 2;
}

@media screen and (max-width: 700px) {
  #steps-section {
    margin: 45px auto 96px auto;
  }
}
</style>
