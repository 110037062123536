<template>
  <div class="presignup">
    <div class="success">
      Nice, wenn Symp diesen Sommer launcht, weißt du als eine/-r der Ersten
      bescheid!
    </div>
    <div class="gif">
      <div
        style="
          border-radius: 20px;
          width: 100%;
          height: 0;
          padding-bottom: 56%;
          position: relative;
        "
      >
        <iframe
          src="https://giphy.com/embed/g9582DNuQppxC"
          width="100%"
          height="100%"
          style="position: absolute; border-radius: 14px"
          frameBorder="0"
          class="giphy-embed"
          allowFullScreen
        ></iframe>
      </div>
    </div>
    <div class="header">Hol dir schonmal die App!</div>
    <div class="store_buttons">
      <a
        target="_blank"
        href="https://play.google.com/store/apps/details?id=me.symp.android"
        ><img src="../assets/images/google_play.png"
      /></a>
      <a
        target="_blank"
        href="https://apps.apple.com/us/app/symp-real-life-connections/id1601091731"
        ><img src="../assets/images/app_store.png"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreSignup",
};
</script>

<style scoped>
.header {
  font-size: 32px;
  text-align: center;
  margin-top: 64px;
  font-weight: 700;
  margin-bottom: 16px;
  font-style: italic;
}

.store_buttons {
  display: flex;
  margin-top: 24px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.store_buttons a img {
  width: auto;
  height: 60px;
  padding: 0;
  margin: 0;
}

.store_buttons a {
  margin: 8px 16px;
  padding: 0;
  width: 200px;
  height: fit-content;
}
.presignup {
  max-width: 1332px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 32px 45px;
  box-sizing: border-box;
}
.beta_link_wrapper {
  margin-top: 16px;
  background-color: white;
  text-decoration: none;
  color: #16191c;
  font-weight: 800;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 18px 48px 18px 48px;
  border-radius: 200px;
  font-size: 22px;
  transition: 0.2s ease-in-out;
}

.disabled {
  opacity: 0.3;
}

.gif {
  margin-top: 50px;
  width: 100%;
  border-radius: 20px;
  max-width: 400px;
}

.beta_link_wrapper:hover {
  transform: scale(1.05);
  transition: 0.2s ease-in-out;
}
.logo {
  width: 100%;
}

.success {
  margin-top: 32px;
  font-weight: 800;
  font-style: italic;
  max-width: 400px;
  font-size: 17px;
  color: #c19aff;
  letter-spacing: 0px;
  text-align: center;
}


input {
  margin-top: 32px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  padding: 15px 20px 15px 20px;
  outline: none;
  background-color: transparent;
  transition: 0.25s ease-out;
  font-family: "Inferno";
  border: none;
  color: #fff;
  font-size: 28px;
  letter-spacing: 0.5px;
  font-weight: 400;
  border-bottom: 2px solid #fff;
}
input::-webkit-input-placeholder {
  font-family: "Inferno";
  color: #91a3aa;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
}
input::-moz-placeholder {
  font-family: "Inferno";
  color: #91a3aa;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
}
input:-ms-input-placeholder {
  font-family: "Inferno";
  color: #91a3aa;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
}
input:-moz-placeholder {
  font-family: "Inferno";
  color: #91a3aa;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
}
input[type="password"] {
  letter-spacing: 4px;
}

.error {
  visibility: hidden;
  color: #ff5d73;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  line-height: 30px;
  transition: 0.2s ease-in;
  height: 30px;
  line-height: 30px;
}
.showError {
  visibility: visible;
  transition: 0.2s ease-out;
}
.login-error {
  line-height: 30px;
  height: 30px;
  text-align: center;
  width: 100%;
  display: block;
}

.disabled {
  opacity: 0.4;
  cursor: default;
  pointer-events: none;
}

.disabled * {
  cursor: default;
  pointer-events: none;
}
</style>
