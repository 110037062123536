import Vue from "vue";
import VueRouter from "vue-router";
import Terms from "../views/Terms.vue";
import Home from "../views/Home.vue";
import Privacy from "../views/Privacy.vue";
import PhoneNumber from "../views/PhoneNumber.vue";
import Imprint from "../views/Imprint.vue";
import PreSignup from "../views/Pre-Signup.vue";
import QR from "../views/QR.vue";
import Referral from "../views/Referral.vue";
import PhoneNumberNoBeta from "../views/PhoneNumberNoBeta.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/beta",
    name: "PhoneNumber",
    component: PhoneNumber,
  },
  {
    path: "/phone",
    name: "PhoneNumberNoBeta",
    component: PhoneNumberNoBeta,
  },
  {
    path: "/pre-signup",
    name: "PreSignup",
    component: PreSignup,
  },
  {
    path: "/qr",
    name: "QR",
    component: QR,
  },
  {
    path: "/referral",
    name: "Referral",
    component: Referral,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: Imprint,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
