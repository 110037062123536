<template>
  <div class="home">
    <div class="section main">
      <div class="section_content">
        <div class="heading">
          <h3>
            Stop simping, <br />
            Start symping
          </h3>

          <div class="store_buttons">
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=me.symp.android"
              ><img src="../assets/images/google_play.png"
            /></a>
            <a
              target="_blank"
              href="https://apps.apple.com/us/app/symp-real-life-connections/id1601091731"
              ><img src="../assets/images/app_store.png"
            /></a>
          </div>
        </div>
        <a
          href="https://www.youtube.com/watch?v=oTr33xJF1as"
          target="_blank"
          class="video_wrapper"
        >
          <div class="video_overlay">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="51.175"
              height="51.175"
              viewBox="0 0 51.175 51.175"
            >
              <path
                id="Icon_awesome-play-circle"
                data-name="Icon awesome-play-circle"
                d="M26.15.562A25.587,25.587,0,1,0,51.737,26.15,25.583,25.583,0,0,0,26.15.562ZM38.087,28.626,19.928,39.047a2.481,2.481,0,0,1-3.683-2.167V15.42a2.482,2.482,0,0,1,3.683-2.167l18.159,11.04A2.484,2.484,0,0,1,38.087,28.626Z"
                transform="translate(-0.563 -0.563)"
                fill="#fff"
              />
            </svg>
            Video ansehen
          </div>
        </a>
      </div>
    </div>

    <div class="section section-2">
      <div class="section_content">
        <div class="text">
          Symp ist die neue App, die es dir möglich macht, mit jedem, den du
          attraktiv findest ohne Risiko den ersten Schritt zu machen.
        </div>

        <router-link to="/beta"
          ><span>🥳</span> Beta Squad beitreten</router-link
        >
      </div>
    </div>

    <div class="section section-5">
      <div class="section_content">
        <h3>Unser Team</h3>
        <div class="team">
          <div class="team_member">
            <div class="member_box member-1"></div>
            <div class="member_name">Marc Furier</div>
            <div class="member_desc">
              CEO, Business management, Partnerships, Legal
            </div>
          </div>
          <div class="team_member">
            <div class="member_box member-2"></div>
            <div class="member_name">Niclas Illg</div>
            <div class="member_desc">
              CTO, App Development, UX/UI Design, Branding
            </div>
          </div>
          <div class="team_member">
            <div class="member_box member-3"></div>
            <div class="member_name">Bertil Braun</div>
            <div class="member_desc">
              Full-Stack Developer, Project Architecture, Backend Development
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section section-4">
      <div class="section_content">
        <h3>Philosophie</h3>
        <div class="philosophy">
          Symp steht für echte Begegnungen in der realen Welt und sorgt dafür,
          dass gegenseitige Anziehung nicht mehr verschwendet wird, nur weil man
          nicht davon wusste. Sobald sich zwei Menschen gegenseitig attraktiv
          finden, wollen wir ihnen die Chance geben sich näher kennenzulernen
          und herauszufinden, ob aus der anfänglichen Anziehung mehr werden
          kann. Das Ganze soll einfach, effizient und diskret ablaufen, ohne
          persönliche oder berufliche Beziehungen zu gefährden oder jemanden in
          eine unangenehme Lage zu versetzen. Lass dich nicht mehr durch falsche
          Scham oder soziale Normen von deinen Wünschen abbringen, sondern folge
          deinen wahren Gefühlen.
        </div>
      </div>
      <div class="content-image"></div>
    </div>

    <div class="footer_links">
      <router-link to="/terms"> AGB's </router-link>
      <router-link to="/privacy"> Datenschutz </router-link>
      <router-link to="/imprint"> Impressum </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",

  methods: {
    onBetaClicked() {},
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 0;
  box-sizing: border-box;
}

.section {
  min-height: 550px;
  height: 100vh;
  width: 100%;
  padding: 0 45px;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

.section_content {
  max-width: 1232px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;
  padding: 100px 0;
}

.section_content h3 {
  font-size: 72px;
  font-style: italic;
  color: white;
}

.main {
  height: 75vh;
  box-sizing: border-box;
  padding-bottom: 0;
  min-height: 400px;
}

.main .section_content {
  display: flex;
  align-items: flex-end;
  position: relative;
}

.section-2 {
  height: 60vh;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-position: center;
  background-image: url("../assets/images/section_2_bg.png");
}

.section-2 .section_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.section-2 .section_content a {
  margin-top: 32px;
  color: #0c0e0f;
  background-color: #2a22f6;
  border-radius: 100px;
  color: white;
  box-sizing: border-box;
  display: block;
  font-size: 18px;
  padding: 16px 24px;
  font-style: italic;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.section-2 .section_content a span {
  font-size: 24px;
  font-style: normal;
  margin-right: 16px;
}

.section-3 {
  background-color: #0c0e0f;
}

.cities {
  display: flex;
  margin-top: 64px;
}

.city {
  margin-right: 32px;
  width: 250px;
}

.city-box {
  height: 250px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
}

.city-1 {
  background-image: url("../assets/images/karlsruhe.png");
}

.city-2 {
  background-image: url("../assets/images/frankfurt.png");
}

.city-name {
  font-size: 28px;
  margin-top: 24px;
}

.your-city-box {
  display: flex;
  background-color: #000000;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 24px;
}

.your-city-box-heading {
  font-size: 24px;
  text-align: center;
}

.your-city-box-text {
  font-weight: 300;
  text-align: center;
  margin: 16px 0;
  font-size: 14px;
}

.your-city-box-button {
  height: 50px;
  background-color: white;
  width: 100%;
  border-radius: 200px;
  color: #0c0e0f;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-style: italic;
  cursor: pointer;
  text-decoration: none;
}

.your-city-box-button span {
  font-style: normal;
  font-size: 18px;
  margin-right: 8px;
}

.section-4 {
  height: 80vh;
  min-height: 700px;
  background-color: white;
  display: flex;
  position: relative;
}

.content-image {
  width: 40%;
  min-width: 300px;
  position: absolute;
  right: 0;
  height: 100%;
  background-image: url("../assets/images/couple.png");
  background-size: cover;
  background-position: center;
}

.section-4 h3 {
  color: #0c0e0f;
}

.philosophy {
  color: #0c0e0f;
  font-size: 18px;
  width: 500px;
  margin-top: 48px;
  line-height: 30px;
}

.section-5 {
}

.section-5 .section_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.team_member {
  width: 200px;
  margin: 32px 32px;
}

.member_box {
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.member_name {
  font-size: 20px;
  text-align: center;
}

.member_desc {
  font-size: 12px;
  text-align: center;
  margin-top: 8px;
  color: #779cff;
}

.member-1 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/radar-ce6a0.appspot.com/o/config%2FScreenshot%202021-11-30%20at%2011.13.09.png?alt=media&token=918bdf10-a5b1-4700-bfca-12e24a97354b");
}
.member-2 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/radar-ce6a0.appspot.com/o/config%2FIMG_20211005_174607.png?alt=media&token=f0610610-cbf3-4257-ac97-dd229fe629ba");
}
.member-3 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/radar-ce6a0.appspot.com/o/config%2FIMG_20190728_171639_136_1.png?alt=media&token=0a4df36f-456e-4f3c-8034-8481c0d713ef");
}

.section-5 .section_content h3 {
  text-align: center;
}

.text {
  color: #16191c;
  max-width: 470px;
  text-align: center;
  margin: 0 auto;
  letter-spacing: -1px;
  font-size: 32px;
  font-weight: 600;
}

.heading {
  text-align: left;
}

.heading h3 {
  font-weight: 600;
  font-size: 100px;
  font-style: italic;
  letter-spacing: -3px;
  line-height: 100px;
  text-align: left;
  margin-bottom: 32px;
}

.heading img {
  margin-right: 16px;
  height: 50px;
}

.logo {
  width: 100%;
}
.video_wrapper {
  right: 0;
  z-index: 0;
  height: 400px;
  position: absolute;
  width: 100%;
  max-width: 600px;
  border-radius: 20px;
  background-color: antiquewhite;
  margin: 32px auto 0 auto;
  margin-bottom: 48px;
  background-size: cover;
  text-decoration: none;
  background-position: center;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/radar-ce6a0.appspot.com/o/config%2Fthumb.png?alt=media&token=11af41ca-ce53-4e06-8eb1-e0b72e0f66d8);
}

.video_wrapper a {
  text-decoration: none;
  cursor: pointer;
}

.video_overlay {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 25px;
  font-style: italic;
}

.video_overlay svg {
  margin-bottom: 16px;
}

.beta_link_wrapper {
  margin-top: 32px;
  background-color: white;
  text-decoration: none;
  color: #16191c;
  font-weight: 800;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px 48px 20px 48px;
  border-radius: 200px;
  font-size: 22px;
  transition: 0.2s ease-in-out;
}

.beta_link_wrapper:hover {
  transform: scale(1.05);
  transition: 0.2s ease-in-out;
}

.beta_link_icon {
  font-style: normal;
  margin-right: 16px;
  font-size: 25px;
}

.subtext {
  margin-top: 48px;
  font-weight: 800;
  font-style: italic;
  max-width: 400px;
  line-height: 25px;
  font-size: 17px;
  letter-spacing: -0.5px;
  text-align: center;
}

.footer_links {
  margin-top: 64px;
}

.footer_links a {
  text-decoration: none;
  margin: 0 16px;
  color: #fff;
}

@media screen and (max-width: 700px) {
  .main {
    height: fit-content;
    flex-direction: column;
    align-items: center;
  }
  .section {
    height: fit-content;
  }
  .section_content {
    height: fit-content;
  }
  .section_content h3 {
    text-align: center;
    width: 100%;
    font-size: 46px;
    line-height: 50px;
    font-style: italic;
    color: white;
  }
  .main .section_content {
    flex-direction: column;
    align-items: center;
    padding: 0;
    padding-bottom: 64px;
    align-items: center;
  }
  .video_wrapper {
    right: 0;
    z-index: 0;
    height: 400px;
    position: relative;
    width: 100%;
    max-width: 600px;
    border-radius: 20px;
    background-color: antiquewhite;
    margin: 0;
  }

  .team_member {
    width: 100%;
  }

  .team_member {
    width: 100%;
  }

  .store_buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;
  }

  .store_buttons a img {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
  }

  .store_buttons a {
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    height: 100px;
    width: 200px;
  }

  .section-2 {
    height: 60vh;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-position: center;
    background-image: url("../assets/images/section_2_bg.png");
  }

  .section-2 .section_content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cities {
    flex-wrap: wrap;
  }

  .city {
    width: 100%;
    margin: 32px 0;
  }

  .section-4 {
    background-color: white;
    flex-direction: column;
    position: relative;
    padding: 64px 45px;
  }

  .section-4 .section_content {
    flex-direction: column;
    padding: 0;
  }

  .section-4 .section_content h3 {
    color: #0c0e0f;
  }

  .philosophy {
    width: 100%;
  }

  .content-image {
    width: 100%;
    border-radius: 16px;
    margin-top: 64px;
    position: relative;
    height: 300px;
    background-image: url("../assets/images/couple.png");
    background-size: cover;
    background-position: center;
  }
}
</style>
