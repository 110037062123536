import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  Timestamp,
} from "firebase/firestore/lite";

// Get a Firestore instance
const app = initializeApp({
  apiKey: "AIzaSyCYQwayUrVb3QtZJjxUjJMjnG_nMNTEUDM",
  authDomain: "radar-ce6a0.firebaseapp.com",
  projectId: "radar-ce6a0",
  storageBucket: "radar-ce6a0.appspot.com",
  messagingSenderId: "921486296773",
  appId: "1:921486296773:web:597dbf374e1624aea992ac",
  measurementId: "G-JXT6LSFCXF",
});

const db = getFirestore(app);

// Get a list of cities from your database
async function addPhoneNumber(phoneNumber) {
  const launchNotificationList = collection(db, "launch_notification_list");
  await addDoc(launchNotificationList, {
    phone: phoneNumber,
    ts: Timestamp.now(),
  });
}

export { addPhoneNumber, db };
// TODO should this be added?  db.settings({ timestampsInSnapshots: true })
