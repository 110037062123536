<template>
  <div class="privacy">
    <div id="steps-section">
      <div class="faq_section">
        <div class="sub_subheading_1">Unsere Verpflichtung Ihnen gegenüber</div>
        <div class="content">
          Bei Symp steht Ihre Privatsphäre an erster Stelle. Deshalb haben wir
          Symp so konzipiert, dass wir mit einem Minimum an persönlichen Daten
          von Ihnen auskommen, um Ihnen unseren Service zur Verfügung stellen zu
          können
        </div>

        <h1 class="privacy_heading">Datenschutz</h1>

        <div class="content">
          Herzlich willkommen zu den Datenschutzbestimmungen von Symp. Vielen
          Dank, dass Sie sich die Zeit nehmen, um sich mit ihnen vertraut zu
          machen. Diese Datenschutzerklärung klärt Sie über die Art, den Umfang
          und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend
          kurz "Daten"). Im Hinblick auf die verwendeten Begrifflichkeiten, wie
          z.B. "Verarbeitung" oder "Verantwortlicher" verweisen wir auf die
          Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
        </div>

        <div class="sub_subheading">Inhalt</div>
        <div class="content">
          <ul>
            <li>Verantwortliche</li>
            <li>Gültigkeitsbereich dieser Datenschutzrichtlinie</li>
            <li>Maßgebliche Rechtsgrundlagen</li>
            <li>Sicherheitsmaßnahmen</li>
            <li>Arten der verarbeiteten Daten</li>
            <li>Verwendung Ihrer Daten</li>
            <li>Teilen Ihrer Daten mit Dritten</li>
            <li>Datenübermittlung in Drittländer</li>
            <li>Rechte der betroffenen Person</li>
            <li>Löschung Ihrer Daten</li>
            <li>Änderungen und Aktualisierung der Datenschutzrichtlinie</li>
            <li>Kontakt</li>
          </ul>
        </div>

        <div class="sub_subheading">1. Verantwortliche</div>
        <div class="content">
          Symp UG (haftungsbeschränkt) <br />
          Birkenstraße. 14 <br />
          67067 Ludwigshafen <br />
          Deutschland <br />
        </div>

        <div class="sub_subheading">
          2. Gültigkeitsbereich dieser Datenschutzrichtlinie
        </div>
        <div class="content">
          Diese Datenschutzrichtlinie gilt für Websites, Apps, Veranstaltungen
          und andere Dienste, die wir unter der Marke Symp betreiben. Um es
          möglichst einfach zu halten, bezeichnen wir in den vorliegenden
          Datenschutzbestimmungen alles oben Genannte als unsere „Dienste“.
          Außerdem haben wir in allen anwendbaren Diensten Links zu diesen
          Datenschutzbestimmungen eingefügt.
        </div>

        <div class="sub_subheading">3. Maßgebliche Rechtsgrundlagen</div>
        <div class="content">
          Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen
          unserer Datenverarbeitungen mit. Für Nutzer aus dem Geltungsbereich
          der Datenschutzgrundverordnung (DSGVO), d.h. der EU und des EWG gilt,
          sofern die Rechtsgrundlage in der Datenschutzerklärung nicht genannt
          wird, Folgendes: <br />
          Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs. 1
          lit. a und Art. 7 DSGVO; Die Rechtsgrundlage für die Verarbeitung zur
          Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen
          sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO; Die
          Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen
          Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO; Für den Fall, dass
          lebenswichtige Interessen der betroffenen Person oder einer anderen
          natürlichen Person eine Verarbeitung personenbezogener Daten
          erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
          Rechtsgrundlage. Die Rechtsgrundlage für die erforderliche
          Verarbeitung zur Wahrnehmung einer Aufgabe, die im öffentlichen
          Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem
          Verantwortlichen übertragen wurde ist Art. 6 Abs. 1 lit. e DSGVO. Die
          Rechtsgrundlage für die Verarbeitung zur Wahrung unserer berechtigten
          Interessen ist Art. 6 Abs. 1 lit. f DSGVO.Die Verarbeitung von Daten
          zu anderen Zwecken als denen, zu denen sie erhoben wurden, bestimmt
          sich nach den Vorgaben des Art 6 Abs. 4 DSGVO.Die Verarbeitung von
          besonderen Kategorien von Daten (entsprechend Art. 9 Abs. 1 DSGVO)
          bestimmt sich nach den Vorgaben des Art. 9 Abs. 2 DSGVO.
        </div>

        <div class="sub_subheading">4. Sicherheitsmaßnahmen</div>
        <div class="content">
          Wir treffen nach Maßgabe der gesetzlichen Vorgabenunter
          Berücksichtigung des Stands der Technik, der Implementierungskosten
          und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung
          sowie der unterschiedlichen Eintrittswahrscheinlichkeit und Schwere
          des Risikos für die Rechte und Freiheiten natürlicher Personen,
          geeignete technische und organisatorische Maßnahmen, um ein dem Risiko
          angemessenes Schutzniveau zu gewährleisten. Zu den Maßnahmen gehören
          insbesondere die Sicherung der Vertraulichkeit, Integrität und
          Verfügbarkeit von Daten durch Kontrolle des physischen Zugangs zu den
          Daten, als auch des sie betreffenden Zugriffs, der Eingabe,
          Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des
          Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von
          Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung der
          Daten gewährleisten. Ferner berücksichtigen wir den Schutz
          personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von
          Hardware, Software sowie Verfahren, entsprechend dem Prinzip des
          Datenschutzes durch Technikgestaltung und durch datenschutzfreundliche
          Voreinstellungen.
        </div>

        <div class="sub_subheading">5. Arten der verarbeiteten Daten</div>
        <div class="content">
          Unsere Dienste können wir Ihnen nur anbieten, wenn wir einige Daten
          von Ihnen kennen. Wir erfassen auch Informationen über Ihre Nutzung
          unserer Dienste, z. B. Zugriffsprotokolle, sowie Informationen von
          Dritten. Im Folgenden finden Sie weitere Informationen hierzu.

          <div class="sub_sub_subheading">
            <br />
            Uns von Ihnen zur Verfügung gestellte Daten
          </div>
          Bei der Nutzung unserer Dienste entscheiden Sie sich dazu, uns gewisse
          Daten zur Verfügung zu stellen. Dies beinhaltet:
          <ul>
            <li>
              Wenn Sie ein Konto einrichten, teilen Sie uns Ihre Telefonnummer
              sowie Ihr Geschlecht mit.
            </li>
            <li>
              Um Symp auch tatsächlich nutzen zu können, müssen Sie bei Ihrem
              Profil noch ein Profilbild hochladen. Um Fotos hinzuzufügen,
              können Sie uns erlauben, auf Ihre Kamera und Ihr Fotoalbum
              zuzugreifen.
            </li>
            <li>
              Wenn Sie Ihr Profil vervollständigen, können Sie uns zusätzliche
              Informationen zu äußerlichen Merkmalen, wie z.B. Ihrer Haarfarbe,
              und ihrem präferierten Geschlecht mitteilen.
            </li>
            <li>
              Sie können uns den Zugriff auf Ihre Kontaktliste erlauben, sodass
              Sie auch das „Contacts“ – Feature von Symp nutzen können. Dann
              sehen Sie einerseits die Kontakte, die auch Symp nutzen und das
              „Contacts“ – Feature aktiviert haben und können von allen Nutzern
              gesehen werden, die Sie wiederum eingespeichert haben und das
              Feature nutzen.
            </li>
            <li>
              Falls Sie sich mit unserem Kundendienst in Verbindung setzen,
              erfassen wir die Daten, die Sie uns während der Interaktion zur
              Verfügung stellen.
            </li>
            <li>
              Wenn Sie uns Informationen über andere Personen mitteilen (z. B.
              wenn Sie die Kontaktdaten eines Freundes für eine bestimmte
              Funktion verwenden), verarbeiten wir diese Informationen in Ihrem
              Namen, um Ihre Anfrage zu bearbeiten.
            </li>
            <li>
              Natürlich verarbeiten wir auch Ihre Chats mit anderen Nutzern
              sowie die von Ihnen veröffentlichten Inhalte, soweit dies für den
              Betrieb unserer Dienste erforderlich ist.
            </li>
          </ul>

          <div class="sub_sub_subheading">
            <br />
            Bei Nutzung unserer Dienste erfasste Informationen
          </div>

          Wenn Sie unsere Dienste nutzen, werden technische Daten darüber
          erzeugt, welche Funktionen Sie genutzt haben, wie Sie sie genutzt
          haben und welche Geräte Sie für den Zugriff auf unsere Dienste
          verwenden. Weitere Details hierzu finden Sie unterhalb:

          <ul>
            <li>
              Die Nutzung der Dienste generiert Daten über Ihre Aktivitäten auf
              unseren Diensten, z. B. darüber, wie Sie sie nutzen (z. B. wann
              Sie sich angemeldet haben, welche Funktionen Sie genutzt haben,
              welche Aktionen Sie durchgeführt haben, welche Informationen Ihnen
              angezeigt wurden, auf welche Webseiten Sie verwiesen wurden und
              mit welchen Anzeigen Sie interagiert haben) und wie Sie mit
              anderen Nutzern interagieren (z. B. mit welchen Nutzern Sie
              interagieren, wann Sie mit ihnen kommuniziert haben, wie viele
              Nachrichten Sie empfangen und gesendet haben).
            </li>
            <li>
              Wir sammeln Informationen von und über Ihr Gerät, welches Sie
              verwenden, um auf unsere Dienste zuzugreifen, einschließlich
              Hardware- und Software-Informationen wie IP-Adresse, Geräte-ID und
              -typ, App-Einstellungen und -Charakteristika, App-Abstürze,
              Werbe-IDs (zufällig generierte Nummern, die Sie über die
              Einstellungen Ihres Geräts zurücksetzen können), Kennungen in
              Verbindung mit Cookies oder anderen Technologien, die ein Gerät
              oder einen Browser eindeutig identifizieren können.
            </li>
            <li>
              Da Symp Ihnen die Möglichkeit bietet mit Menschen in Ihrem
              unmittelbaren Umfeld in Kontakt zu treten, benötigen wir Zugriff
              auf Ihren aktuellen Standort. Die Erlaubnis dafür wird beim
              Erstellen eines Kontos abgefragt und kann jederzeit über die
              Einstellungen widerrufen werden. Um die beste Erfahrung mit Symp
              zu haben und nicht ständig die App offen haben zu müssen,
              empfehlen wir den Zugriff auf den aktuellen Standort auch zu
              erlauben, wenn die App nur im Hintergrund offen ist.
            </li>
          </ul>
        </div>

        <div class="sub_subheading">6. Verwendung Ihrer Daten</div>
        <div class="content">
          Wir nutzen Ihre Daten hauptsächlich zur Erbringung und Verbesserung
          unserer Dienste. Zusätzlich nutzen wir Ihre Daten, um Sie zu schützen
          und Ihnen die Art von Werbung anzubieten, die Sie interessieren
          könnte. Nachfolgend finden sie eine detailliertere Erklärung der
          verschiedenen Gründe, aus denen wir Ihre Daten verwenden, zusammen mit
          praktischen Beispielen.

          <div class="sub_sub_subheading">
            <br />
            A. Kontoführung und Bereitstellung unserer Dienste
          </div>
          <ul>
            <li>Kontoerstellung und Kontoverwaltung</li>
            <li>Kundenbetreuung</li>
            <li>Kommunikation mit Ihnen über unsere Dienste</li>
            <li>Ausführung Ihrer Transaktionen</li>
          </ul>

          <div class="sub_sub_subheading">
            <br />
            B. Kontaktaufnahme mit anderen Nutzern
          </div>
          <ul>
            <li>
              Um Ihnen andere Nutzer, die sich in Ihrer unmittelbaren Umgebung
              befinden, anzeigen zu können.
            </li>
            <li>
              Um Sie bei anderen Nutzern, die sich in Ihrer unmittelbaren
              Umgebung befinden, anzuzeigen.
            </li>
          </ul>
          <div class="sub_sub_subheading">
            <br />
            C. Verbesserung und Entwicklung unserer Dienste
          </div>
          <ul>
            <li>
              Um zu verstehen, wie die Nutzer die Dienste typischerweise nutzen,
              um sie zu verbessern (zum Beispiel können wir beschließen, das
              Design zu ändern oder sogar bestimmte Funktionen zu modifizieren,
              je nachdem, wie die Nutzer darauf reagieren)
            </li>
            <li>
              Um neue Funktionen und Dienste zu entwickeln (zum Beispiel könnten
              wir uns dazu entscheiden, aufgrund von Nutzeranfragen, neue
              interessenbasierte Funktionen zu entwickeln)
            </li>
          </ul>

          <div class="sub_sub_subheading">
            <br />
            D. Bekämpfung, Aufdeckung und Vorbeugung von Betrug und anderen
            illegalen oder nicht autorisierten Aktivitäten
          </div>
          <ul>
            <li>
              Um vermutete, angebliche oder laufende Verstöße gegen unsere
              Nutzungsbedingungen, insbesondere durch die Überprüfung von
              Meldungen und Interaktionen zwischen Members (Mitgliedern) zu
              finden und zu bearbeiten
            </li>
            <li>
              Zum besseren Verständnis und der Entwicklung von Gegenmaßnahmen
              gegen Verletzungen unserer Nutzungsbedingungen
            </li>
            <li>
              Um Daten im Zusammenhang mit Verstößen gegen unsere
              Nutzungsbedingungen aufzubewahren, um erneuten Vorfällen
              vorzubeugen
            </li>
            <li>
              Um unsere Rechte durchzusetzen oder auszuüben, beispielsweise
              unsere Nutzungsbedingungen
            </li>
            <li>
              Um Personen, die eine Meldung einreichen, mitzuteilen, was wir
              aufgrund ihrer Meldung unternommen haben
            </li>
          </ul>
          <div class="sub_sub_subheading">
            <br />
            E. Einhaltung von Rechtsvorschriften
          </div>
          <ul>
            <li>Unterstützung von Strafverfolgungen</li>
            <li>Um rechtlichen Anforderungen gerecht zu werden</li>
          </ul>
          <br />
          <br />
          Um Ihre Daten wie in dieser Datenschutzerklärung beschrieben zu
          verarbeiten, stützen wir uns auf die folgenden Rechtsgrundlagen:
          <div class="sub_sub_subheading">
            <br />
            Um Ihnen unsere Dienste zu bieten:
          </div>
          Wir verarbeiten Ihre Informationen für die oben genannten Zwecke A und
          B, um den Vertrag, den Sie mit uns geschlossen haben, zu erfüllen.
          Wenn Sie z.B. unseren Service nutzen, um mit Personen in Ihrem Umfeld
          in Kontakt zu kommen, verwenden wir Ihre Daten, um Ihr Profil zu
          pflegen, es für andere Nutzer sichtbar zu machen und Ihnen andere
          Nutzer anzuzeigen und um Ihnen und anderen Nutzern unsere kostenlosen
          und kostenpflichtigen Funktionen zur Verfügung stellen zu können.
          <div class="sub_sub_subheading"><br />Berechtigtes Interesse:</div>
          Wir verarbeiten Ihre Daten für die oben genannten Zwecke C und D,
          basierend auf unserem berechtigten Interesse. So analysieren wir z.B.
          das Nutzerverhalten auf unseren Diensten, um unser Angebot
          kontinuierlich zu verbessern, und wir schlagen Ihnen Angebote vor, die
          Sie interessieren könnten. Wir bewerben unsere eigenen Dienste, wir
          verarbeiten Daten, um dazu beizutragen, unsere Nutzer zu schützen und
          wir verarbeiten Daten, wenn nötig, um unsere Rechte durchzusetzen,
          Strafverfolgungsbehörden zu unterstützen und um uns selbst im Falle
          eines Rechtsstreits verteidigen zu können.
          <div class="sub_sub_subheading">
            <br />
            Einhaltung geltender Gesetze und Vorschriften:
          </div>
          Wir verarbeiten Ihre Daten für den oben genannten Zweck E, wenn es für
          uns notwendig ist, geltende Gesetze und Vorschriften einzuhalten und
          unsere Einhaltung geltender Gesetze und Vorschriften nachzuweisen. Wir
          bewahren beispielsweise Traffic-Daten und Daten über Transaktionen in
          Übereinstimmung mit unseren buchhalterischen, steuerlichen und anderen
          gesetzlichen Verpflichtungen zur Datenaufbewahrung auf und können auf
          berechtigte Anfragen von Strafverfolgungsbehörden reagieren. Wir
          bewahren auch Daten auf, die die Einwilligung von Nutzern und deren
          Entscheidungen, eine bestimmte Funktion oder Verarbeitung abzulehnen,
          belegen.
          <div class="sub_sub_subheading">
            <br />
            Einwilligung
          </div>
          Wenn Sie sich dafür entscheiden, uns Informationen zur Verfügung zu
          stellen, die in bestimmten Ländern als „besonders“ oder „sensibel“
          gelten, wie z. B. Ihre sexuelle Orientierung, erklären Sie sich mit
          der Verarbeitung dieser Informationen in Übereinstimmung mit dieser
          Datenschutzrichtlinie einverstanden. Wir können Sie um Einwilligung
          bitten, dass wir spezifische Informationen wie Ihre genaue
          Geolokalisierung sammeln dürfen oder Ihre Daten aus spezifischen
          Gründen verwenden dürfen. In einigen Fällen können Sie Ihre
          Einwilligung widerrufen, indem Sie Ihre Einstellungen anpassen (z. B.
          in Bezug auf das Sammeln Ihrer genauen Geolokalisierung) oder indem
          Sie Ihren Content löschen (z. B. wenn Sie Informationen in Ihrem
          Profil eingegeben haben, die als „besonders“ oder „sensibel“ angesehen
          werden können). Sie können jederzeit Ihre Einwilligung widerrufen,
          indem Sie uns über die Adresse am Ende dieser Datenschutzrichtlinie
          kontaktieren.
        </div>

        <div class="sub_subheading">7. Teilen Ihrer Daten mit Dritten</div>
        <div class="content">
          Unser Ziel besteht darin, Ihnen zu helfen, mit Menschen, denen Sie im
          echten Leben begegnen und die Sie gern näher kennenlernen möchten, in
          Kontakt zu kommen. Deshalb wird ihr Profilbild mit Nutzern in Ihrer
          Nähe geteilt. Wir geben einige Nutzer-Daten auch an Dienstleister und
          Partner weiter, die uns beim Betrieb der Dienste unterstützen. Lesen
          Sie weiter, um mehr darüber zu erfahren, wie Ihre Daten an andere
          weitergegeben werden.
          <div class="sub_sub_subheading">
            <br />
            An Dienstanbieter und Partner
          </div>
          Wir arbeiten mit Drittparteien zusammen, um unsere Dienste auszuführen
          und zu verbessern. Diese Drittparteien unterstützen uns bei
          zahlreichen Aufgaben, einschließlich dem Daten-Hosting und der
          Datenpflege, der Kundenbetreuung, dem Marketing, der Werbung, der
          Zahlungsabwicklung und den Sicherheitsoperationen. Wir dürfen Ihre
          Daten auch an Partner weitergeben, die uns bei Vertrieb und
          Vermarktung unserer Dienste unterstützen. So dürfen wir beispielsweise
          eingeschränkte Informationen über Sie in gehashter, von Menschen nicht
          lesbarer Form, an Werbepartner weitergeben. Bevor wir einen
          Dienstanbieter engagieren oder mit irgendeinem Partner
          zusammenarbeiten, führen wir einen detaillierten Überprüfungsprozess
          durch. Unsere Dienstleister und Partner müssen sich zu strenger
          Vertraulichkeit verpflichten.
          <div class="sub_sub_subheading">
            <br />
            Mit Strafverfolgungsbehörden (falls gesetzlich vorgeschrieben)
          </div>
          Wir dürfen Ihre Daten weitergeben, wenn dies vernünftigerweise
          erforderlich ist: (i) um einem rechtlichen Verfahren, wie einen
          Gerichtsbeschluss, eine Vorladung oder einem Durchsuchungsbefehl,
          einer behördlichen/strafrechtlichen Ermittlung oder sonstigen
          gesetzlichen Vorgaben Folge zu leisten; (ii) um eine
          Verbrechensprävention oder -aufklärung zu unterstützen (in jedem Fall
          nach Maßgabe des geltenden Rechts); oder (iii) um die Sicherheit einer
          anderen Person zu schützen.
          <div class="sub_sub_subheading">
            <br />
            Durchsetzung gesetzlicher Rechte
          </div>
          Wir dürfen auch Informationen freigeben: (i) falls die Freigabe unsere
          Haftbarkeit in einer tatsächlich eingeleiteten oder angedrohten Klage
          verringern würde; (ii) falls dies notwendig ist, um unsere
          gesetzlichen Rechte und die gesetzlichen Rechte unserer Nutzer,
          Geschäftspartner oder sonstiger Interessenten zu schützen; (iii) um
          unsere Vereinbarung mit Ihnen umzusetzen; und (iv) um illegale
          Aktivitäten, vermuteten Betrug oder sonstiges Fehlverhalten zu
          untersuchen, zu verhindern oder andere Maßnahmen dagegen zu ergreifen.
          <div class="sub_sub_subheading">
            <br />
            Bei Unternehmenstransaktionen
          </div>
          Falls wir ganz oder teilweise an einer Fusion, einem Verkauf, einem
          Erwerb, einer Veräußerung, einer Neustrukturierung, einer
          Neuorganisation, einer Auflösung, einer Insolvenz oder einem sonstigen
          Eigentümer- oder Kontrollwechsel beteiligt sind, dürfen wir Ihre Daten
          ebenfalls weitergeben.
          <div class="sub_sub_subheading">
            <br />
            Auf Ihren Wunsch oder mit Ihrer Einwilligung
          </div>
          Eventuell bitten wir Sie um Ihre Einwilligung zur Weitergabe Ihrer
          Daten an Dritte. Falls dies so ist, werden wir deutlich machen,
          weshalb wir die Daten weitergeben möchten. Unter irgendeinem der oben
          genannten Umstände dürfen wir nicht-personenbezogene Daten (Daten, die
          allein nicht ausreichen, um Sie als Person zu identifizieren, wie
          beispielsweise Geräteinformationen, allgemeine demographische
          Informationen, allgemeine Verhaltensdaten, Geolokalisierung in
          anonymisierter Form), aber auch personenbezogene Daten in gehashter,
          für Menschen nicht lesbarer Form, nutzen und weitergeben. Wir dürfen
          diese Informationen außerdem an Drittparteien (insbesondere
          Werbetreibende) weitergeben, um zielgerichtete Werbung in unseren
          Diensten und auf Websites oder in Anwendungen von Drittparteien zu
          entwickeln und bereitzustellen, und um Werbung, die Sie sehen, zu
          analysieren. Wir dürfen diese Informationen mit weiteren
          nicht-personenbezogenen Informationen oder personenbezogenen Daten in
          gehashter, für Menschen nicht lesbarer Form, kombinieren, die aus
          anderen Quellen erfasst wurden.
        </div>

        <div class="sub_subheading">8. Datenübermittlung in Drittländer</div>

        <div class="content">
          Die Weitergabe von Informationen, wie dargestellt in Abschnitt 7
          beinhaltet grenzüberschreitende Datenübertragungen in die Vereinigten
          Staaten von Amerika und andere Länder, in denen möglicherweise andere
          Gesetze für die Datenverarbeitung gelten. Wenn wir personenbezogene
          Daten außerhalb des EWR, des Vereinigten Königreichs, der Schweiz oder
          anderer Länder übermitteln, deren Datenschutzgesetze von der
          Europäischen Kommission oder einer anderen zuständigen
          Regierungsbehörde als angemessen eingestuft wurden, verwenden wir
          Standardvertragsklauseln (Standardvertragsklauseln sind
          Verpflichtungen zwischen Unternehmen, die personenbezogene Daten
          übermitteln, und verpflichten sie zum Schutz der Privatsphäre und der
          Sicherheit Ihrer Daten) oder andere geeignete
          Übermittlungsmechanismen. Konkret nutzen wir Google Firebase.
        </div>

        <div class="sub_subheading">9. Rechte der betroffenen Person</div>
        <div class="content">
          Die Kontrolle über Ihre Daten soll bei Ihnen bleiben. Deshalb möchten
          wir Sie an die folgenden Möglichkeiten und Tools erinnern, die Ihnen
          zur Verfügung stehen:

          <ul class="normal_list">
            <li>
              Tools und Kontoeinstellungen, die Ihnen dabei helfen können, auf
              Informationen, die Sie uns zur Verfügung gestellt haben und die
              innerhalb des jeweiligen Dienstes in direktem Zusammenhang mit
              Ihrem Profil stehen, zuzugreifen, diese zu berichtigen oder zu
              entfernen. Falls Sie hinsichtlich dieser Tools und Einstellungen
              Fragen haben, kontaktieren Sie uns bitte unter der Adresse am Ende
              dieser Datenschutzrichtlinie.
            </li>
            <li>
              Mobile Plattformen können über Berechtigungssysteme für bestimmte
              Arten von Gerätedaten und Benachrichtigungen verfügen, z. B. für
              Telefonkontakte, Bilder, Standortdienste, Push-Benachrichtigungen
              und Werbekennungen. Sie können Ihre Einstellungen auf Ihrem Gerät
              so ändern, dass Sie der Erhebung oder Verarbeitung der
              entsprechenden Daten oder der Anzeige der entsprechenden
              Benachrichtigungen entweder zustimmen oder sie ablehnen. Wenn Sie
              das tun, kann es natürlich sein, dass bestimmte Dienste nicht mehr
              funktionieren.
            </li>
            <li>
              Sie können die Erfassung sämtlicher Informationen durch eine App
              beenden, indem Sie diese deinstallieren. Denken Sie daran, dass
              die Deinstallation einer App NICHT zur Schließung Ihres Kontos
              führt. Um Ihr Konto zu schließen, verwenden Sie bitte die
              entsprechende Funktion des Dienstes.
            </li>
            <li>
              Sie können Ihr Konto löschen, indem Sie direkt im jeweiligen
              Dienst die zugehörige Funktion verwenden.
            </li>
          </ul>

          <br />
          Wir möchten, dass Sie sich Ihrer Datenschutzrechte bewusst sind. Hier
          sind einige Schlüsselaspekte, die Sie sich merken sollten:

          <ul class="normal_list">
            <li>
              Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
              betreffende Daten verarbeitet werden und auf Auskunft über diese
              Daten sowie auf weitere Informationen und Kopie der Daten
              entsprechend den gesetzlichen Vorgaben. Stellen Sie dazu einen
              solchen Antrag über die Adresse am Ende dieser
              Datenschutzrichtlinie.
            </li>
            <li>
              Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die
              Vervollständigung der Sie betreffenden Daten oder die Berichtigung
              der Sie betreffenden unrichtigen Daten zu verlangen. Kontaktieren
              Sie uns diesbezüglich bitte unter der Adresse am Ende dieser
              Datenschutzrichtlinie.
            </li>
            <li>
              Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten,
              die Sie uns bereitgestellt haben nach Maßgabe der gesetzlichen
              Vorgaben zu erhalten und deren Übermittlung an andere
              Verantwortliche zu fordern.
            </li>
            <li>
              Widerrufsrecht: Sie haben das Recht, erteilte Einwilligungen mit
              Wirkung für die Zukunft zu widerrufen.
            </li>
            <li>
              Widerspruchsrecht: Sie können der künftigen Verarbeitung der Sie
              betreffenden Daten nach Maßgabe der gesetzlichen Vorgaben
              jederzeit widersprechen.
            </li>
          </ul>

          Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das Recht,
          eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
        </div>
        <div class="sub_subheading">10. Löschung Ihrer Daten</div>
        <div class="content">
          Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
          Vorgaben gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern
          nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben,
          werden die bei uns gespeicherten Daten gelöscht, sobald sie für ihre
          Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine
          gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten
          nicht gelöscht werden, weil sie für andere und gesetzlich zulässige
          Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h.
          die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das
          gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen
          aufbewahrt werden müssen.
        </div>
        <div class="sub_subheading">
          11. Änderungen und Aktualisierung der Datenschutzrichtlinie
        </div>
        <div class="content">
          Da wir immer auf der Suche nach neuen und innovativen Wegen sind, um
          die Kontaktaufnahme im echten Leben zu vereinfachen, und uns bemühen,
          die Erklärungen zu unseren Datenpraktiken auf dem neuesten Stand zu
          halten, kann sich diese Richtlinie im Laufe der Zeit ändern. Wir
          bitten Sie sich regelmäßig über den Inhalt unserer
          Datenschutzerklärung zu informieren. Wir passen die
          Datenschutzerklärung an, sobald die Änderungen der von uns
          durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
          informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung
          Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle
          Benachrichtigung erforderlich wird.
        </div>
        <div class="sub_subheading">12. Kontakt</div>
        <div class="content">
          Per Mail: contact@symp.me
          <br />
          Per Post: Symp UG (haftungsbeschränkt)
          <br />
          Birkenstraße. 14
          <br />
          67067 Ludwigshafen
          <br />
          Deutschland
        </div>
      </div>
    </div>
    <div class="footer_links">
      <router-link to="/terms"> AGB's </router-link>
      <router-link to="/privacy"> Datenschutz </router-link>
      <router-link to="/imprint"> Impressum </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Privacy",

  methods: {
    handleSignupClick() {
      this.$emit("event", "get_started_click");
    },
  },
};
</script>

<style scoped>
.privacy {
  max-width: 1332px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  margin-top: 32px;
  padding: 32px 45px;
  box-sizing: border-box;
}

.footer_links {
  margin-top: 64px;
}

.footer_links a {
  text-decoration: none;
  margin: 0 16px;
  color: #fff;
}

.privacy_heading {
  font-size: 48px;
  margin: 100px 0 0 0;
  color: #fff;
  font-weight: 700;
  text-align: left;
}

.subheading {
  margin: 63px 0;
  font-size: 24px;
  font-weight: 600;
}

.sub_subheading {
  font-size: 18px;
  margin: 64px 0 0 0;
  font-weight: 500;
}

.sub_subheading_1 {
  font-size: 18px;
  margin: 0 0 0 0;
  font-weight: 500;
}

.hint {
  color: #797676;
  font-size: 14px;
  margin-top: 16px;
}

.sub_sub_subheading {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 600;
}

.content {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 32px;
  padding: 32px;
  border-radius: 16px;
  color: #d6bcff;
  box-sizing: border-box;
  background-color: #221cc0;
}

.content ul {
  box-sizing: border-box;
  padding: 0 24px;
  list-style-type: decimal;
}

.content .normal_list {
  list-style-type: circle;
}

.content li {
  color: #d6bcff;
  margin-bottom: 16px;
}

.content li:last-child {
  margin: 0;
}

.content li::marker {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  text-align: left;
}

.content li ul {
  list-style-type: circle;
}
</style>
