<template>
  <div class="privacy">Wir leiten dich zur App...</div>
</template>

<script>
export default {
  name: "QR",
  mounted() {
    window.location.assign("https://symps.page.link/join");
  },
  methods: {
    handleSignupClick() {
      this.$emit("event", "get_started_click");
    },
  },
};
</script>

<style scoped>
.privacy {
  max-width: 1332px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 48px;
  color: white;
  font-style: italic;
  font-weight: 600;
  justify-content: flex-start;
  margin: 0 auto;
  margin-top: 32px;
  padding: 32px 45px;
  box-sizing: border-box;
}

.privacy_heading {
  font-size: 48px;
  margin: 100px 0 0 0;
  color: #fff;
  font-weight: 700;
  text-align: left;
}

.subheading {
  margin: 63px 0;
  font-size: 24px;
  font-weight: 600;
}

.sub_subheading {
  font-size: 18px;
  margin: 64px 0 0 0;
  font-weight: 500;
}

.sub_subheading_1 {
  font-size: 18px;
  margin: 0 0 0 0;
  font-weight: 500;
}

.hint {
  color: #797676;
  font-size: 14px;
  margin-top: 16px;
}

.sub_sub_subheading {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 600;
}

.content {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 32px;
  padding: 32px;
  border-radius: 16px;
  color: #d6bcff;
  box-sizing: border-box;
  background-color: #221cc0;
}

.content ul {
  box-sizing: border-box;
  padding: 0 24px;
  list-style-type: decimal;
}

.content .normal_list {
  list-style-type: circle;
}

.content li {
  color: #d6bcff;
  margin-bottom: 16px;
}

.content li:last-child {
  margin: 0;
}

.content li::marker {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  text-align: left;
}

.content li ul {
  list-style-type: circle;
}
</style>
